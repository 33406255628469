<template>
    <section class="mt-2 mb-2"> 
      <div class="bg-body border border-primary rounded px-3 py-2">
        <div class="d-flex flex-row gap-3 align-items-center">
          <!-- icon -->
          <i class="bi bi-question-circle text-primary fs-2"></i>
          <!-- text -->
          <div class="d-flex flex-column justify-content-center">
            <div class="fw-bold">Account not found.</div>
            <div class="small">Sorry, we couldn't find an account with the provided information. Please check your details and try again.</div>
          </div>
        </div>
      </div>
    </section>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "AccountDetailsStatsSkeleton",
  components: {},
  setup() {
    return {};
  },
});
</script>
