<template>
  <txn-summary />
  <loading v-if="loading" />
  <error v-else-if="error" />
</template>

<script>
import { defineComponent, computed, onMounted, onUnmounted } from "vue";
import { useStore } from "vuex";
import TxnSummary from "@/components/summaries/TxnSummary.vue";
import Loading from "@/components/Loading.vue";
import Error from "@/components/Error.vue";

export default defineComponent({
  name: "TransactionsTab",
  components: {
    TxnSummary,
    Loading,
    Error,
  },
  setup() {
    const store = useStore();
    store.dispatch("accounts/fetchMoreTxnsForAccount");

    const scroll = () => {
      window.onscroll = () => {
        let bottomOfWindow =
          window.innerHeight + window.pageYOffset + 1 >=
          document.body.scrollHeight;

        if (bottomOfWindow) {
          store.dispatch("accounts/fetchMoreTxnsForAccount");
        }
      };
    };
    onMounted(scroll);
    onUnmounted(() => {
      window.onscroll = null;
    });
    const loading = computed(
      () => store.getters["transactions/getLoadingStatus"]
    );
    const error = computed(() => store.getters["transactions/getError"]);
    return { loading, error, scroll };
  },
});
</script>
