<template>
  <div class="d-flex mb-2">
    <div class="flex-fill">
      <div class="input-group">
        <search />
      </div>
    </div>
  </div>
  <account-details-stats :net-worth="netWorth" :muxed-account="muxedAccount" />
</template>
<script>

import { defineComponent, ref, computed } from "vue";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import { formatDate } from "@/utils/date";
import Search from "@/components/Search.vue";
import AccountDetailsStats from "@/components/stats/AccountDetailsStats.vue";
import { extractBaseAddress, MuxedAccount } from "stellar-sdk";


export default defineComponent({
  name: "AccountDetails",
  components: {
    Search,
    AccountDetailsStats,
  },
  setup() {
    const store = useStore();
    const route = useRoute();

    const currTab = ref("operations");

    const acc = computed(() => store.getters["accounts/getAccount"]);
    const assetsMeta = computed(
      () => store.getters["accounts/getAccountAssetsMeta"].data
    );
    const server = computed(() => store.getters["settings/getApiServer"]);
    const muxedAccount = ref(null);
    const accountId = ref(route.params.acc_id);
    if (accountId.value.startsWith("M")) {
      try {
        accountId.value = extractBaseAddress(route.params.acc_id);
        server.value.loadAccount(accountId.value).then((res) => {
          muxedAccount.value = new MuxedAccount.fromAddress(
            route.params.acc_id,
            res.sequence
          );
        });
        // eslint-disable-next-line no-empty
      } catch (err) {}
    }
    store.dispatch("accounts/fetchAccountById", accountId.value);

    const netWorth = computed(() => {
      let netWorth = 0;
      acc.value.balances?.forEach((balance) => {
        if (balance.asset_type === "native") {
          netWorth += parseFloat(balance.balance);
        }
        netWorth +=
          parseFloat(
            assetsMeta.value[balance.asset_code + "_" + balance.asset_issuer]
              ?.price_xlm || 0
          ) * balance.balance;
      });
      return netWorth;
    });

    const onTabChange = (tab) => {
      store.dispatch("accounts/resetEmptySlate");
      currTab.value = tab.name;
    };

    const navTabs = [
      { name: "operations", icon: "bi-view-list" },
      { name: "transactions", icon: "bi-list-ul" },
      { name: "payments", icon: "bi-arrow-right-circle-fill" },
      { name: "effects", icon: "bi-clipboard-check" },
      { name: "offers", icon: "bi-file-text" },
      { name: "trades", icon: "bi-arrow-left-right" },
      { name: "assets", icon: "bi-wallet" },
      { name: "other", icon: "bi-file-earmark-text" },
    ];

    return {
      formatDate,
      navTabs,
      onTabChange,
      currTab,
      netWorth,
      accountId,
      server,
      muxedAccount,
    };
  },
});
</script>
