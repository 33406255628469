<template>
  <template v-if="balances">
  <div class="list-group">
    <div
      class="list-group-item"
      v-for="(balance, idx) in balances"
      :key="idx"
    >
    <div class="row row-cols-1 row-cols-xxl-4 row-cols-2 g-2">
      <div class="col">
        <div class="card h-100 border-0">
          <div class="card-body d-flex p-0">
            <asset-details-link
              :type="balance.asset_type"
              :code="balance.asset_code"
              :issuer="balance.asset_issuer"
              class="flex-shrink-0 d-flex me-2 align-self-center"
              style="height: 36px; width: 36px"
            >
              <img
                v-if="balance.asset_type === 'native'"
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                :data-bs-original-title="$t('operation_types.asset_icon')"
                v-tooltip
                :src="xlmLogo"
                class="px-1 align-self-center mx-auto img-fluid"
              />
              <img
                v-else-if="assetMeta(balance)?.image"
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                :data-bs-original-title="$t('operation_types.asset_icon')"
                v-tooltip
                :src="assetMeta(balance)?.image"
                class="px-1 align-self-center mx-auto img-fluid"
              />
              <i
                v-else
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                :data-bs-original-title="
                  $t('operation_types.asset_icon_not_available')
                "
                v-tooltip
                class="
                  bi bi-circle-fill
                  fs-5
                  align-self-center
                  mx-auto
                  text-muted
                "
              ></i>
            </asset-details-link>
            <div class="flex-grow-1">
              <div
                class="
                  fw-bold
                  text-reset
                  font-monospace
                  small
                  d-flex
                  align-items-center
                  justify-content-between
                "
              >
                <asset-details-link
                  :type="balance.asset_type"
                  :code="balance.asset_code"
                  :issuer="balance.asset_issuer"
                  class="text-decoration-none text-reset"
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  data-bs-original-title="Asset Name"
                  >{{
                    balance.asset_type === "native"
                      ? $t("operation_types.xlm")
                      : balance.asset_code
                  }}</asset-details-link
                >
              </div>
              <div
                class="
                  font-monospace
                  small
                  d-flex
                  align-items-center
                  justify-content-between
                "
              >
                <asset-details-link
                  v-if="balance.asset_type === 'native'"
                  :type="balance.asset_type"
                  class="text-muted text-decoration-none"
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  :data-bs-original-title="
                    $t('operation_types.native_asset_of_stellar_network')
                  "
                  v-tooltip
                >
                  {{ $t("operation_types.native") }}
                </asset-details-link>
                <asset-details-link
                  v-else
                  type="balance.asset_type"
                  :code="balance.asset_code"
                  :issuer="balance.asset_issuer"
                  class="text-muted text-decoration-none"
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  :data-bs-original-title="$t('operation_types.asset_issuer')"
                  v-tooltip
                  v-middle-ellipsis="{
                    size: 6,
                    text: balance.asset_issuer,
                  }"
                ></asset-details-link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col d-none d-xxl-block">
        <div class="card h-100 border-0">
          <div class="card-body d-flex p-0">
            <div class="align-self-center w-100">
              <div
                v-if="percentageOfNetWorth(balance) !== '0.0000'"
                class="font-monospace small d-flex d-block mb-1"
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                :data-bs-original-title="
                  $t('account_details.percentage_from_net_worth')
                "
                v-tooltip
              >
                <span class="mx-auto"
                  >{{ percentageOfNetWorth(balance) }}%</span
                >
              </div>
              <div
                v-else
                class="font-monospace small d-flex d-block mb-1"
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                :data-bs-original-title="
                  $t('account_details.price_xlm_not_found')
                "
                v-tooltip
              >
                <span class="mx-auto"> 0%</span>
              </div>
              <div class="progress w-100" style="height: 7px">
                <div
                  class="progress-bar bg-primary"
                  role="progressbar"
                  :style="`width: ${percentageOfNetWorth(balance)}%`"
                  :aria-valuenow="percentageOfNetWorth(balance)"
                  aria-valuemin="0"
                  aria-valuemax="100"
                ></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col d-none d-md-block"></div>
      <div class="col">
        <div class="card h-100 border-0">
          <div
            class="
              card-body
              d-flex
              p-0
              justify-content-end justify-content-md-between
            "
          >
            <span
              v-if="!assetMeta(balance)"
              class="
                badge
                bg-opacity-25
                align-self-center
                ms-2
                text-secondary
                bg-secondary
                d-none d-xxl-block
              "
              ><i class="bi bi-caret-right-fill"></i>

              0%</span
            >
            <span
              v-else
              class="
                badge
                bg-opacity-25
                align-self-center
                ms-2
                d-none d-xxl-block
              "
              :class="{
                'text-danger bg-danger':
                  assetMeta(balance)?.price_usd_change < 0,
                'text-secondary bg-secondary':
                  assetMeta(balance)?.price_usd_change === 0,
                'text-success bg-success':
                  assetMeta(balance)?.price_usd_change > 0,
              }"
              ><i
                class="bi"
                :class="{
                  'bi-caret-down-fill':
                    assetMeta(balance)?.price_usd_change < 0,
                  'bi-caret-right-fill':
                    assetMeta(balance)?.price_usd_change === 0,
                  'bi-caret-up-fill': assetMeta(balance)?.price_usd_change > 0,
                }"
              ></i>

              {{ assetMeta(balance)?.price_usd_change }}%</span
            >

            <div class="">
              <asset-details-link
                :type="balance.asset_type"
                :code="balance.asset_code"
                :issuer="balance.asset_issuer"
                class="
                  fw-bold
                  text-decoration-none text-reset
                  font-monospace
                  small
                "
                >{{
                  parseFloat(balance.balance).toLocaleString(_, {
                    minimumFractionDigits: 7,
                  }) +
                  " " +
                  (balance.asset_type !== "native"
                    ? balance.asset_code
                    : " XLM")
                }}</asset-details-link
              >
              <p class="small mb-0 font-monospace text-end">
                <asset-details-link
                  :type="balance.asset_type"
                  :code="balance.asset_code"
                  :issuer="balance.asset_issuer"
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  :data-bs-original-title="
                    $t('account_details.estimated_value')
                  "
                  v-tooltip
                  class="text-muted text-decoration-none small"
                  >{{ assetValue(balance) }}</asset-details-link
                >
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  </div>
  </template>
  <template v-else>
    <no-content />
  </template>
</template>
<script>
import { defineComponent, computed } from "vue";
import { useStore } from "vuex";
import AssetDetailsLink from "@/components/links/AssetDetailsLink.vue";
import NoContent from "@/components/NoContent.vue";

export default defineComponent({
  name: "AssetsTab",
  components: { AssetDetailsLink, NoContent },
  props: {
    netWorth: Number,
  },
  setup(props) {
    const store = useStore();
    const assetsMeta = computed(
      () => store.getters["accounts/getAccountAssetsMeta"].data
    );
    const assetMeta = (asset) =>
      assetsMeta.value[asset.asset_code + "_" + asset.asset_issuer];
    const xlmLogo = require("@/assets/images/assets/xlm.png");
    const balances = computed(() =>
      store.getters["accounts/getAccount"].balances?.filter(
        (balance) => !["liquidity_pool_shares"].includes(balance.asset_type)
      )
    );
    const convertPrices = computed(
      () => store.getters["charts/getConversionPrices"].data
    );
    const assetValue = (balance) => {
      if (balance.asset_type === "native") {
        return (
          "$ " +
          (balance.balance * convertPrices.value["xlm_usd"]).toLocaleString(
            {},
            {
              minimumFractionDigits: 4,
            }
          )
        );
      }
      return assetMeta(balance)?.price_usd
        ? "$ " +
            parseFloat(
              balance.balance * assetMeta(balance)?.price_usd
            ).toLocaleString({}, { minimumFractionDigits: 4 })
        : "$ -";
    };
    const percentageOfNetWorth = (asset) => {
      if (asset.asset_type === "native") {
        return (
          (parseFloat(asset.balance) / props.netWorth) * 100 || 0
        ).toFixed(4);
      }
      return (
        ((parseFloat(asset.balance) * parseFloat(assetMeta(asset)?.price_xlm)) /
          props.netWorth) *
          100 || 0
      ).toFixed(4);
    };
    return {
      store,
      assetMeta,
      balances,
      xlmLogo,
      percentageOfNetWorth,
      assetValue,
    };
  },
});
</script>
