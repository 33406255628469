<template>
  <payments-summary />
  <loading v-if="loading" />
  <error v-else-if="error" />
</template>

<script>
import { defineComponent, computed, onMounted, onUnmounted } from "vue";
import { useStore } from "vuex";
import PaymentsSummary from "@/components/summaries/PaymentsSummary.vue";
import Loading from "@/components/Loading.vue";
import Error from "@/components/Error.vue";
export default defineComponent({
  name: "PaymentsTab",
  components: {
    PaymentsSummary,
    Loading,
    Error,
  },
  setup() {
    const store = useStore();

    store.dispatch("accounts/fetchMorePaymentsForAccount");
    const loading = computed(() => store.getters["payments/getLoadingStatus"]);
    const error = computed(() => store.getters["payments/getError"]);

    const scroll = () => {
      window.onscroll = () => {
        let bottomOfWindow =
          window.innerHeight + window.pageYOffset + 1 >=
          document.body.scrollHeight;

        if (bottomOfWindow) {
          store.dispatch("accounts/fetchMorePaymentsForAccount");
        }
      };
    };
    onMounted(scroll);
    onUnmounted(() => {
      window.onscroll = null;
    });

    return { loading, error };
  },
});
</script>