<template>
  <trades-summary />
  <loading v-if="loading" />
  <error v-else-if="error" />
</template>

<script>
import { defineComponent, computed, onMounted, onUnmounted } from "vue";
import { useStore } from "vuex";
import TradesSummary from "@/components/summaries/TradesSummary.vue";
import Loading from "@/components/Loading.vue";
import Error from "@/components/Error.vue";

export default defineComponent({
  name: "TradesTab",
  components: {
    TradesSummary,
    Loading,
    Error,
  },
  setup() {
    const store = useStore();
    store.dispatch("accounts/fetchMoreTradesForAccount");

    const loading = computed(() => store.getters["trades/getLoadingStatus"]);
    const error = computed(() => store.getters["trades/getError"]);

    const scroll = () => {
      window.onscroll = () => {
        let bottomOfWindow =
          window.innerHeight + window.pageYOffset + 1 >=
          document.body.scrollHeight;

        if (bottomOfWindow) {
          store.dispatch("accounts/fetchMoreTradesForAccount");
        }
      };
    };
    onMounted(scroll);
    onUnmounted(() => {
      window.onscroll = null;
    });
    return { loading, error };
  },
});
</script>