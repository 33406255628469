<template>
  <template v-if="objEmpty(account.data_attr)">
    <div v-if="objEmpty(account.data_attr)" class="card mb-2">
      <div class="card-header bg-light">
        {{ $t("account_details.data") }}
      </div>
      <div class="card-body">
        <ul class="list-group">
          <li
            v-for="(key, val) in account.data_attr"
            :key="key"
            class="list-group-item d-flex p-0 border-0 mt-1"
          >
            <div
              class="w-25 py-2 px-3 bg-light"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              :data-bs-original-title="$t('account_details.key')"
              v-tooltip
            >
              {{ key }}
            </div>
            <div
              class="w-75 py-2 px-3"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              :data-bs-original-title="$t('account_details.value')"
              v-tooltip
            >
              {{ val }}
            </div>
          </li>
        </ul>
      </div>
    </div>
    <div v-if="objEmpty(account.flags)" class="card mb-2">
      <div class="card-header bg-light">
        {{ $t("account_details.flags") }}
      </div>
      <div class="card-body">
        <ul class="list-group">
          <li
            v-for="(val, key) in account.flags"
            :key="key"
            class="list-group-item d-flex p-0 border-0 mt-1"
          >
            <div class="w-25 py-2 px-3 bg-light">
              {{ splitAndCapitalize(key, "_") }}
            </div>
            <div class="w-75 py-2 px-3">
              <i v-if="val" class="bi bi-check-circle"></i>
              <i v-else class="bi bi-x-circle"></i>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <div v-if="thresholds.length" class="card mb-2">
      <div class="card-header bg-light">
        {{ $t("account_details.thresholds") }}
      </div>
      <div class="card-body">
        <ul class="list-group">
          <li
            v-for="key in thresholds"
            :key="key"
            class="list-group-item d-flex p-0 border-0"
          >
            <div class="w-25 py-2 px-3 bg-light">
              {{ splitAndCapitalize(key, "_") }}
            </div>
            <div class="w-75 py-2 px-3">
              {{ account.thresholds[key] }}
            </div>
          </li>
        </ul>
      </div>
    </div>
    <div v-if="objEmpty(account.signers)" class="card mb-2">
      <div class="card-header bg-light">
        {{ $t("account_details.signers") }}
      </div>
      <div class="card-body">
        <ul class="list-group">
          <li
            v-for="(signer, key) in account.signers"
            :key="key"
            class="list-group-item d-flex p-0 border-0"
          >
            <div
              class="w-75 py-2 px-3 bg-light font-monospace"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              :data-bs-original-title="$t('account_details.account_key')"
              v-tooltip
            >
              <router-link
                :to="{
                  name: 'AccountDetailsRoute',
                  params: { acc_id: signer.key },
                }"
                class="text-decoration-none"
              >
                {{ signer.key }}</router-link
              >
            </div>
            <div
              class="w-25 py-2 px-3"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              :data-bs-original-title="$t('account_details.weight')"
              v-tooltip
            >
              {{ signer.weight }}
            </div>
          </li>
        </ul>
      </div>
    </div>
  </template>
  <template v-else>
    <no-content />
  </template>
</template>

<script>
import { defineComponent, computed } from "vue";
import { useStore } from "vuex";
import { splitAndCapitalize } from "@/utils/string.js";
import NoContent from "@/components/NoContent.vue";

export default defineComponent({
  name: "OtherTab",
  components: { NoContent },
  setup() {
    const store = useStore();
    const account = computed(() => store.getters["accounts/getAccount"]);

    const thresholds = computed(() =>
      account.value && account.value.thresholds
        ? Object.keys(account.value.thresholds)
        : []
    );
    const objEmpty = (obj) => {
      return obj && Object.keys(obj).length;
    };
    return { account, splitAndCapitalize, thresholds, objEmpty };
  },
});
</script>
