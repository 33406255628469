<template>
  <account-details-stats-skeleton v-if="loading" />
  <account-details-stats-not-found-skeleton v-else-if="error" />
  <template v-else
    ><div class="card-group text-center mb-2">
      <div class="card">
        <div class="card-body">
          <div class="d-flex">
            <div
              class="flex-shrink-0 border border-2 rounded-circle d-flex"
              style="height: 44px; width: 44px"
            >
              <span
                class="
                  fw-bold
                  small
                  rounded-circle
                  align-self-center
                  mx-auto
                  text-primary
                "
                >XLM</span
              >
            </div>
            <div class="flex-grow-1 ms-3">
              <div class="float-start text-start">
                <span
                  class="text-muted d-block text-uppercase"
                  style="font-size: 12px"
                  >{{ $t("account_details.net_worth_in_xlm") }}</span
                >
                <span class="fw-bold mb-0 d-block">{{
                  netWorth.toLocaleString(_, { minimumFractionDigits: 4 })
                }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="card">
        <div class="card-body">
          <div class="d-flex">
            <div
              class="flex-shrink-0 border border-2 rounded-circle d-flex"
              style="height: 44px; width: 44px"
            >
              <i
                class="
                  bi bi-currency-bitcoin
                  fs-4
                  rounded-circle
                  align-self-center
                  mx-auto
                  text-primary
                "
              ></i>
            </div>
            <div class="flex-grow-1 ms-3">
              <div class="float-start text-start">
                <span
                  class="text-muted d-block text-uppercase"
                  style="font-size: 12px"
                  >{{ $t("account_details.net_worth_in_btc") }}</span
                >
                <span class="fw-bold mb-0 d-block">{{
                  convertPrices?.xlm_btc
                    ? (
                        Math.round(
                          netWorth *
                            parseFloat(convertPrices?.xlm_btc) *
                            Math.pow(10, 8)
                        ) / Math.pow(10, 8)
                      ).toLocaleString(_, { minimumFractionDigits: 8 })
                    : "-"
                }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="card">
        <div class="card-body">
          <div class="d-flex">
            <div
              class="flex-shrink-0 border border-2 rounded-circle d-flex"
              style="height: 44px; width: 44px"
            >
              <i
                class="
                  bi bi-currency-dollar
                  fs-4
                  rounded-circle
                  align-self-center
                  mx-auto
                  text-primary
                "
              ></i>
            </div>
            <div class="flex-grow-1 ms-3">
              <div class="float-start text-start">
                <span
                  class="text-muted d-block text-uppercase"
                  style="font-size: 12px"
                  >{{ $t("account_details.net_worth_in_usd") }}</span
                >
                <span class="fw-bold mb-0 d-block">{{
                  convertPrices?.xlm_usd
                    ? (
                        Math.round(
                          netWorth *
                            parseFloat(convertPrices?.xlm_usd) *
                            Math.pow(10, 4)
                        ) / Math.pow(10, 4)
                      ).toLocaleString(_, { minimumFractionDigits: 4 })
                    : "-"
                }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="card">
        <div class="card-body">
          <div class="d-flex">
            <div
              class="flex-shrink-0 border border-2 rounded-circle d-flex"
              style="height: 44px; width: 44px"
            >
              <i
                class="
                  bi bi-currency-euro
                  fs-4
                  rounded-circle
                  align-self-center
                  mx-auto
                  text-primary
                "
              ></i>
            </div>
            <div class="flex-grow-1 ms-3">
              <div class="float-start text-start">
                <span
                  class="text-muted d-block text-uppercase"
                  style="font-size: 12px"
                  >{{ $t("account_details.net_worth_in_eur") }}</span
                >
                <span class="fw-bold mb-0 d-block">{{
                  convertPrices?.xlm_eur
                    ? (
                        Math.round(
                          netWorth *
                            parseFloat(convertPrices?.xlm_eur) *
                            Math.pow(10, 4)
                        ) / Math.pow(10, 4)
                      ).toLocaleString(_, { minimumFractionDigits: 4 })
                    : "-"
                }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="card">
        <div class="card-body">
          <div class="d-flex">
            <div
              class="flex-shrink-0 border border-2 rounded-circle d-flex"
              style="height: 44px; width: 44px"
            >
              <i
                class="
                  bi bi-currency-pound
                  fs-4
                  rounded-circle
                  align-self-center
                  mx-auto
                  text-primary
                "
              ></i>
            </div>
            <div class="flex-grow-1 ms-3">
              <div class="float-start text-start">
                <span
                  class="text-muted d-block text-uppercase"
                  style="font-size: 12px"
                  >{{ $t("account_details.net_worth_in_gbp") }}</span
                >
                <span class="fw-bold mb-0 d-block">{{
                  convertPrices?.xlm_gbp
                    ? (
                        Math.round(
                          netWorth *
                            parseFloat(convertPrices?.xlm_gbp) *
                            Math.pow(10, 4)
                        ) / Math.pow(10, 4)
                      ).toLocaleString(_, { minimumFractionDigits: 4 })
                    : "-"
                }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="['Scam', 'Hack'].includes(acc.meta?.label?.name)"
      class="
        alert
        border-danger
        bg-danger bg-opacity-10
        d-flex
        align-items-center
        py-2
        mb-2
      "
      role="alert"
    >
      <span
        class="
          flex-shrink-0
          bg-danger bg-opacity-25
          rounded-3
          d-flex
          align-self-center
          me-3
        "
        style="height: 36px; width: 36px"
      >
        <i
          class="
            bi bi-exclamation-triangle-fill
            fs-5
            align-self-center
            mx-auto
            text-danger
          "
        ></i>
      </span>
      <div>
        <span class="fw-bold text-danger font-monospace small d-block">{{
          $t("account_details.warning")
        }}</span>
        <span class="small">{{ $t("account_details.marked_as_spam") }}</span>
      </div>
    </div>
    <div class="d-flex">
      <div class="flex-fill">
        <div class="card mb-2">
          <div class="card-body">
            <ul class="list-group">
              <li class="list-group-item d-flex p-0 border-0">
                <div class="w-25 py-2 px-3 ">
                  <i class="bi bi-person-square me-3"></i
                  >{{ $t("account_details.account") }}
                </div>
                <div class="w-75 py-2 px-3">
                  <span class="align-self-center small font-monospace">{{
                    acc.account_id
                  }}</span>
                  <router-link
                    to="#"
                    class="float-end text-decoration-none"
                    @click="copy(acc.account_id)"
                    >{{ $t("effect_types.copy") }}</router-link
                  >
                </div>
              </li>
              <li
                v-if="muxedAccount"
                class="list-group-item d-flex p-0 border-0"
              >
                <div class="w-25 py-2 px-3 ">
                  <i class="bi bi-person-circle me-3"></i
                  >{{ $t("account_details.muxed_account_id") }}
                </div>
                <div class="w-75 py-2 px-3">
                  <span class="align-self-center small font-monospace">{{
                    muxedAccount.id()
                  }}</span>
                  <router-link
                    to="#"
                    class="float-end text-decoration-none"
                    @click="copy(muxedAccount.id())"
                    >{{ $t("effect_types.copy") }}</router-link
                  >
                </div>
              </li>
              <li class="list-group-item d-flex p-0 border-0">
                <div class="w-25 py-2 px-3 ">
                  <i class="bi bi-tag-fill me-3"></i
                  >{{ $t("account_details.label") }}
                </div>
                <div class="w-75 py-2 px-3">
                  <span
                    class="
                      text-decoration-none
                      font-monospace
                      fw-bold
                      small
                    "
                  >
                    {{ acc.meta?.label?.name ?? "-" }}
                  </span>
                  <i
                    v-tooltip
                    class="bi ms-5"
                    :class="labelData(acc)?.icon"
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    :data-bs-original-title="labelData(acc)?.tooltip"
                  ></i>
                  <router-link
                    :to="{
                      name: 'DirectoryUpdateRoute',
                      query: {
                        account: acc.account_id,
                      },
                    }"
                    class="float-end text-decoration-none"
                  >
                    {{ $t("account_details.update") }}
                  </router-link>
                </div>
              </li>
              <li class="list-group-item d-flex p-0 border-0">
                <div class="w-25 py-2 px-3 ">
                  <i class="bi bi-coin me-3"></i
                  >{{ $t("account_details.balance") }}
                </div>
                <div class="w-75 py-2 px-3">
                  <div
                    class="
                      small
                      font-monospace
                      text-decoration-none
                      font-monospace
                    "
                  >
                    {{
                      parseFloat(
                        acc.balances.find(
                          (balance) => balance.asset_type === "native"
                        )?.balance
                      ).toLocaleString(_, {
                        minimumFractionDigits: 7,
                      })
                    }}
                    XLM
                  </div>
                </div>
              </li>
              <li class="list-group-item d-flex p-0 border-0">
                <div class="w-25 py-2 px-3">
                  <i class="bi bi-list-ul me-3"></i
                  >{{ $t("account_details.transactions") }}
                </div>
                <div class="w-75 py-2 px-3">
                  <div
                    class="
                      small
                      font-monospace
                      text-decoration-none
                      font-monospace
                    "
                  >
                    {{
                      acc.meta?.transactions
                        ? parseFloat(acc.meta?.transactions).toLocaleString()
                        : "-"
                    }}
                  </div>
                </div>
              </li>
              <li class="list-group-item d-flex p-0 border-0">
                <div class="w-25 py-2 px-3 ">
                  <i class="bi bi-hash me-3"></i
                  >{{ $t("account_details.rank") }}
                </div>
                <div class="w-75 py-2 px-3">
                  <div
                    class="
                      small
                      font-monospace
                      text-decoration-none
                      font-monospace
                    "
                  >
                    {{
                      acc.meta?.rank
                        ? parseInt(acc.meta?.rank).toLocaleString()
                        : "-"
                    }}
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="ms-2 d-none d-xxl-block">
        <div class="card mb-2">
          <div class="card-body">
            <vue-qrcode
              v-if="acc.account_id"
              :value="acc.account_id"
              :width="200"
              :margin="0"
              :scale="0"
              :color="{ dark: '#000000ff' }"
              :type="'image/png'"
              :quality="0.92"
            />
          </div>
        </div>
      </div>
    </div>
    <account-tabs :net-worth="netWorth" />
    </template>
</template>

<script>
import { defineComponent, computed } from "vue";
import { useStore } from "vuex";
import { useI18n } from "vue-i18n";
import { formatDate } from "@/utils/date.js";
import AccountDetailsStatsSkeleton from "@/components/skeletons/stats/AccountDetailsStatsSkeleton.vue";
import AccountDetailsStatsNotFoundSkeleton from "@/components/skeletons/stats/AccountDetailsStatsNotFoundSkeleton.vue";
import VueQrcode from "vue-qrcode";
import AccountTabs from "@/components/account/AccountTabs.vue";

export default defineComponent({
  name: "AccountDetailsStats",
  components: { AccountTabs, AccountDetailsStatsSkeleton, VueQrcode, AccountDetailsStatsNotFoundSkeleton},
  props: {
    netWorth: [String, Number],
    muxedAccount: Object,
  },
  setup() {
    const store = useStore();
    const { t } = useI18n();
    const acc = computed(() => store.getters["accounts/getAccount"]);
    const loading = computed(() => store.getters["accounts/getLoadingStatus"]);
    const error = computed(() => store.getters["accounts/getError"]);
    const convertPrices = computed(
      () => store.getters["charts/getConversionPrices"].data
    );
    const copy = (value) => {
      navigator.clipboard.writeText(value);
    };
    const labelData = (account) => {
      if (
        account.meta?.label &&
        !["Scam", "Hack"].includes(account.meta?.label?.name)
      ) {
        if (account.meta?.label.verified) {
          return {
            icon: "bi-patch-check-fill text-primary",
            tooltip: t("account_summary.verified_account"),
          };
        } else {
          return {
            icon: "bi-person-circle text-dark",
            tooltip: t("account_summary.unverified_user_defined_label"),
          };
        }
      }
    };
    return { acc, loading, error, formatDate, copy, labelData, convertPrices };
  },
});
</script>
