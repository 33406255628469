<template>
  <div class="btn-group w-100 d-flex flex-row" role="group">
    <div
      v-for="(tab, idx) in navTabs"
      :key="idx"
      :class="`btn border bg-body text-decoration-none w-100
      ${currTab === tab.name ? 'text-primary' : ''}`"
      @click="onTabChange(tab)"
    >
      <i :class="`bi ${tab.icon}`"></i
      ><span class="d-none d-xl-inline d-xxl-inline ms-1">{{
        $t(`account_details.tabs.${tab.name}`)
      }}</span>
    </div>
  </div>
  <div class="mt-2">
    <component
      :is="`${currTab}-tab`"
      v-if="currTab === 'assets'"
      :net-worth="netWorth"
    />
    <component :is="`${currTab}-tab`" v-else />
  </div>
</template>

<script>
import { defineComponent, ref, computed } from "vue";
import { useStore } from "vuex";
import OperationsTab from "@/components/account/OperationsTab.vue";
import PaymentsTab from "@/components/account/PaymentsTab.vue";
import TransactionsTab from "@/components/account/TransactionsTab.vue";
import EffectsTab from "@/components/account/EffectsTab.vue";
import OffersTab from "@/components/account/OffersTab.vue";
import TradesTab from "@/components/account/TradesTab.vue";
import AssetsTab from "@/components/account/AssetsTab.vue";
import OtherTab from "@/components/account/OtherTab.vue";


export default defineComponent({
  name: "AccountTabs",
  components: {
    OperationsTab,
    PaymentsTab,
    TransactionsTab,
    EffectsTab,
    OffersTab,
    TradesTab,
    AssetsTab,
    OtherTab,
  },
  setup() {
    const store = useStore();
    const currTab = ref("operations");
    const server = computed(() => store.getters["settings/getApiServer"]);
    const muxedAccount = ref(null);

    const onTabChange = (tab) => {
      store.dispatch("accounts/resetEmptySlate");
      currTab.value = tab.name;
    };

    const navTabs = [
      { name: "operations", icon: "bi-view-list" },
      { name: "transactions", icon: "bi-list-ul" },
      { name: "payments", icon: "bi-arrow-right-circle-fill" },
      { name: "effects", icon: "bi-clipboard-check" },
      { name: "offers", icon: "bi-file-text" },
      { name: "trades", icon: "bi-arrow-left-right" },
      { name: "assets", icon: "bi-wallet" },
      { name: "other", icon: "bi-file-earmark-text" },
    ];

    return {
      navTabs,
      onTabChange,
      currTab,
      server,
      muxedAccount,
    };
  },
});

</script>
