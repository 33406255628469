<template>
  <div class="card-group text-center mb-2">
    <div class="card">
      <div class="card-body">
        <div class="d-flex">
          <div
            class="flex-shrink-0 border border-2 rounded-circle d-flex"
            style="height: 44px; width: 44px"
          >
            <span
              class="
                fw-bold
                small
                rounded-circle
                align-self-center
                mx-auto
                text-primary
              "
              >XLM</span
            >
          </div>
          <div class="flex-grow-1 ms-3">
            <div class="float-start text-start">
              <span
                class="text-muted d-block text-uppercase"
                style="font-size: 12px"
                >Net Worth in XLM</span
              >
              <span class="fw-bold mb-0 d-block">-</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="card">
      <div class="card-body">
        <div class="d-flex">
          <div
            class="flex-shrink-0 border border-2 rounded-circle d-flex"
            style="height: 44px; width: 44px"
          >
            <i
              class="
                bi bi-currency-bitcoin
                fs-4
                rounded-circle
                align-self-center
                mx-auto
                text-primary
              "
            ></i>
          </div>
          <div class="flex-grow-1 ms-3">
            <div class="float-start text-start">
              <span
                class="text-muted d-block text-uppercase"
                style="font-size: 12px"
                >Net Worth in BTC</span
              >
              <span class="fw-bold mb-0 d-block">-</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="card">
      <div class="card-body">
        <div class="d-flex">
          <div
            class="flex-shrink-0 border border-2 rounded-circle d-flex"
            style="height: 44px; width: 44px"
          >
            <i
              class="
                bi bi-currency-dollar
                fs-4
                rounded-circle
                align-self-center
                mx-auto
                text-primary
              "
            ></i>
          </div>
          <div class="flex-grow-1 ms-3">
            <div class="float-start text-start">
              <span
                class="text-muted d-block text-uppercase"
                style="font-size: 12px"
                >Net Worth in USD</span
              >
              <span class="fw-bold mb-0 d-block">-</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="card">
      <div class="card-body">
        <div class="d-flex">
          <div
            class="flex-shrink-0 border border-2 rounded-circle d-flex"
            style="height: 44px; width: 44px"
          >
            <i
              class="
                bi bi-currency-euro
                fs-4
                rounded-circle
                align-self-center
                mx-auto
                text-primary
              "
            ></i>
          </div>
          <div class="flex-grow-1 ms-3">
            <div class="float-start text-start">
              <span
                class="text-muted d-block text-uppercase"
                style="font-size: 12px"
                >Net Worth in EUR</span
              >
              <span class="fw-bold mb-0 d-block">-</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="card">
      <div class="card-body">
        <div class="d-flex">
          <div
            class="flex-shrink-0 border border-2 rounded-circle d-flex"
            style="height: 44px; width: 44px"
          >
            <i
              class="
                bi bi-currency-pound
                fs-4
                rounded-circle
                align-self-center
                mx-auto
                text-primary
              "
            ></i>
          </div>
          <div class="flex-grow-1 ms-3">
            <div class="float-start text-start">
              <span
                class="text-muted d-block text-uppercase"
                style="font-size: 12px"
                >Net Worth in GBP</span
              >
              <span class="fw-bold mb-0 d-block">-</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="d-flex">
    <div class="flex-fill">
      <div class="card mb-2">
        <div class="card-body">
          <ul class="list-group">
            <li class="list-group-item d-flex p-0 border-0">
              <div class="w-25 py-2 px-3">
                <i class="bi bi-person-square me-3"></i>Account
              </div>
              <div class="w-75 py-2 px-3">
                <span class="align-self-center small font-monospace">-</span>
              </div>
            </li>
            <li class="list-group-item d-flex p-0 border-0">
              <div class="w-25 py-2 px-3">
                <i class="bi bi-tag-fill me-3"></i>Label
              </div>
              <div class="w-75 py-2 px-3">
                <div
                  class="
                    text-decoration-none
                    font-monospace
                    fw-bold
                    small
                  "
                >
                  -
                </div>
              </div>
            </li>
            <li class="list-group-item d-flex p-0 border-0">
              <div class="w-25 py-2 px-3">
                <i class="bi bi-coin me-3"></i>Balance
              </div>
              <div class="w-75 py-2 px-3">
                <div
                  class="
                    small
                    font-monospace
                    text-decoration-none
                    font-monospace
                  "
                >
                  -
                </div>
              </div>
            </li>
            <li class="list-group-item d-flex p-0 border-0">
              <div class="w-25 py-2 px-3">
                <i class="bi bi-list-ul me-3"></i>Transactions
              </div>
              <div class="w-75 py-2 px-3">
                <div
                  class="
                    small
                    font-monospace
                    text-decoration-none
                    font-monospace
                  "
                >
                  -
                </div>
              </div>
            </li>
            <li class="list-group-item d-flex p-0 border-0">
              <div class="w-25 py-2 px-3">
                <i class="bi bi-hash me-3"></i>Rank
              </div>
              <div class="w-75 py-2 px-3">
                <div
                  class="
                    small
                    font-monospace
                    text-decoration-none
                    font-monospace
                  "
                >
                  -
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="ms-2 d-none d-xxl-block">
      <div class="card mb-2">
        <div
          class="card-body"
          :style="{ width: '232px', height: '232px' }"
        ></div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "AccountDetailsStatsSkeleton",
  components: {},
  setup() {
    return {};
  },
});
</script>
