<template>
  <template v-if="offers && offers.length">
  <template v-for="(offer, idx) in offers" :key="idx">
    <div class="card mb-2">
      <div class="card-body py-2">
        <div class="d-flex">
          <div
            class="
              flex-shrink-0
              align-self-center
              d-none d-xxl-inline-flex
              me-1
            "
          >
            <router-link
              class="flex-shrink-0 bg-primary bg-opacity-25 rounded-3 d-flex"
              style="height: 36px; width: 36px"
              data-bs-html="true"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              :data-bs-original-title="`${$t('account_details.offer')} ${idx}`"
              v-tooltip
              :to="{
                name: 'OpDetailsRoute',
                params: {
                  op_id: offer.id,
                },
              }"
            >
              <i
                class="
                  bi bi-file-text
                  fs-5
                  align-self-center
                  mx-auto
                  text-primary
                "
              ></i>
            </router-link>
          </div>
          <div class="flex-grow-1">
            <div class="row g-2">
              <div class="col-xxl-3 d-flex align-items-center">
                <div class="flex-grow-1 ms-2 text-truncate">
                  <router-link
                    :to="{
                      name: 'AccountDetailsRoute',
                      params: {
                        acc_id: offer.seller,
                      },
                    }"
                    class="
                      fw-bold
                      text-decoration-none text-dark
                      font-monospace
                      small
                    "
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    :data-bs-original-title="$t('account_details.label')"
                    v-tooltip
                  >
                    {{
                      accountLabels.data[offer.seller]
                        ? accountLabels.data[offer.seller].label.name
                        : $t("account_details.unknown")
                    }}
                  </router-link>
                  <p class="small mb-0 font-monospace">
                    <router-link
                      :to="{
                        name: 'AccountDetailsRoute',
                        params: {
                          acc_id: offer.seller,
                        },
                      }"
                      class="text-muted text-decoration-none"
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      :data-bs-original-title="$t('account_details.seller')"
                      v-tooltip
                      v-middle-ellipsis="{
                        size: 6,
                        text: offer.seller,
                      }"
                    >
                    </router-link>
                  </p>
                </div>
                <div class="flex-shrink-0 me-2">
                  <account-label :account="offer.seller" />
                </div>
              </div>
              <div class="col-xxl-3 d-flex align-items-center bg-light rounded">
                <div class="flex-grow-1 ms-2">
                  <router-link
                    :to="{
                      name: 'LedgerDetailsRoute',
                      params: {
                        ledger_sequence: offer.last_modified_ledger,
                      },
                    }"
                    class="
                      fw-bold
                      text-decoration-none text-dark
                      font-monospace
                      small
                    "
                  >
                    {{ $t("account_details.ledger") }}
                  </router-link>
                  <p class="small mb-0 font-monospace">
                    <router-link
                      :to="{
                        name: 'LedgerDetailsRoute',
                        params: {
                          ledger_sequence: offer.last_modified_ledger,
                        },
                      }"
                      class="text-muted text-decoration-none small"
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      :data-bs-original-title="
                        $t('account_details.ledger_sequence')
                      "
                      v-tooltip
                    >
                      {{ offer.last_modified_ledger }}
                    </router-link>
                  </p>
                </div>
              </div>
              <div
                class="col-xxl-3 align-items-center d-flex"
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                :data-bs-original-title="$t('account_details.asset_being_sold')"
                v-tooltip
              >
                <asset-details-link
                  class="flex-shrink-0 d-flex mx-1"
                  style="height: 36px; width: 36px"
                  :type="offer.selling.asset_type"
                  :code="offer.selling.asset_code"
                  :issuer="offer.selling.asset_issuer"
                >
                  <img
                    v-if="offer.selling.asset_type === 'native'"
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    :data-bs-original-title="$t('operation_types.asset_icon')"
                    v-tooltip
                    :src="xlmLogo"
                    class="px-1 align-self-center mx-auto img-fluid"
                  />
                  <img
                    v-else-if="assetMeta(offer.selling)?.image"
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    :data-bs-original-title="$t('operation_types.asset_icon')"
                    v-tooltip
                    :src="assetMeta(offer.selling)?.image"
                    class="px-1 align-self-center mx-auto img-fluid"
                  />
                  <i
                    v-else
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    :data-bs-original-title="
                      $t('operation_types.asset_icon_not_available')
                    "
                    v-tooltip
                    class="
                      bi bi-circle-fill
                      fs-5
                      align-self-center
                      mx-auto
                      text-muted
                    "
                  ></i>
                </asset-details-link>
                <div class="flex-grow-1 ms-1">
                  <div
                    class="
                      fw-bold
                      text-dark
                      font-monospace
                      small
                      d-flex
                      align-items-center
                      justify-content-between
                    "
                  >
                    <asset-details-link
                      :type="offer.selling.asset_type"
                      :code="offer.selling.asset_code"
                      :issuer="offer.selling.asset_issuer"
                      class="text-decoration-none text-dark"
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      :data-bs-original-title="$t('account_details.asset_name')"
                      v-tooltip
                    >
                      {{
                        offer.selling.asset_type === "native"
                          ? $t("account_details.xlm")
                          : offer.selling.asset_code
                      }}</asset-details-link
                    >
                    <span> {{ offer.amount }}</span>
                  </div>
                  <div
                    class="
                      text-dark
                      font-monospace
                      small
                      d-flex
                      align-items-center
                      justify-content-between
                    "
                  >
                    <asset-details-link
                      v-if="offer.selling.asset_type === 'native'"
                      :type="offer.selling.asset_type"
                      class="text-muted text-decoration-none"
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      :data-bs-original-title="
                        $t('account_details.native_asset_of_stellar_network')
                      "
                      v-tooltip
                    >
                      {{ $t("account_details.native") }}
                    </asset-details-link>
                    <asset-details-link
                      v-else
                      :type="offer.selling.asset_type"
                      :code="offer.selling.asset_code"
                      :issuer="offer.selling.asset_issuer"
                      class="text-muted text-decoration-none"
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      :data-bs-original-title="
                        $t('account_details.asset_issuer')
                      "
                      v-tooltip
                      v-middle-ellipsis="{
                        size: 6,
                        text: offer.selling.asset_issuer,
                      }"
                    ></asset-details-link>
                  </div>
                </div>
              </div>
              <div
                class="col-xxl-3 align-items-center d-flex bg-light rounded"
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                v-tooltip
                :data-bs-original-title="
                  $t('account_details.asset_being_bought')
                "
              >
                <asset-details-link
                  class="flex-shrink-0 d-flex mx-1"
                  style="height: 36px; width: 36px"
                  :type="offer.buying.asset_type"
                  :code="offer.buying.asset_code"
                  :issuer="offer.buying.asset_issuer"
                >
                  <img
                    v-if="offer.buying.asset_type === 'native'"
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    :data-bs-original-title="$t('operation_types.asset_icon')"
                    v-tooltip
                    :src="xlmLogo"
                    class="px-1 align-self-center mx-auto img-fluid"
                  />
                  <img
                    v-else-if="assetMeta(offer.buying)?.image"
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    :data-bs-original-title="$t('operation_types.asset_icon')"
                    v-tooltip
                    :src="assetMeta(offer.buying)?.image"
                    class="px-1 align-self-center mx-auto img-fluid"
                  />
                  <i
                    v-else
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    :data-bs-original-title="
                      $t('operation_types.asset_icon_not_available')
                    "
                    v-tooltip
                    class="
                      bi bi-circle-fill
                      fs-5
                      align-self-center
                      mx-auto
                      text-muted
                    "
                  ></i>
                </asset-details-link>
                <div class="flex-grow-1 ms-1">
                  <div
                    class="
                      fw-bold
                      text-dark
                      font-monospace
                      small
                      d-flex
                      align-items-center
                      justify-content-between
                    "
                  >
                    <asset-details-link
                      :type="offer.buying.asset_type"
                      :code="offer.buying.asset_code"
                      :issuer="offer.buying.asset_issuer"
                      class="text-decoration-none text-dark"
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      v-tooltip
                      :data-bs-original-title="$t('account_details.asset_name')"
                    >
                      {{
                        offer.buying.asset_type === "native"
                          ? $t("account_details.xlm")
                          : offer.buying.asset_code
                      }}</asset-details-link
                    >
                    <span> {{ (offer.amount * offer.price).toFixed(7) }}</span>
                  </div>
                  <div
                    class="
                      text-dark
                      font-monospace
                      small
                      d-flex
                      align-items-center
                      justify-content-between
                    "
                  >
                    <asset-details-link
                      v-if="offer.buying.asset_type === 'native'"
                      :type="offer.buying.asset_type"
                      class="text-muted text-decoration-none"
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      :data-bs-original-title="
                        $t('account_details.native_asset_of_stellar_network')
                      "
                      v-tooltip
                    >
                      {{ $t("account_details.native") }}
                    </asset-details-link>
                    <asset-details-link
                      v-else
                      :type="offer.buying.asset_type"
                      :code="offer.buying.asset_code"
                      :issuer="offer.buying.asset_issuer"
                      class="text-muted text-decoration-none"
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      :data-bs-original-title="
                        $t('account_details.asset_issuer')
                      "
                      v-tooltip
                      v-middle-ellipsis="{
                        size: 6,
                        text: offer.buying.asset_issuer,
                      }"
                    ></asset-details-link>
                    <span
                      class="small"
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      v-tooltip
                      title=""
                      :data-bs-original-title="
                        formatDate(offer.last_modified_time)
                      "
                      >{{
                        formatDateElapsedTime(offer.last_modified_time)
                      }}</span
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  </template>
  <template v-if="offers && offers.length == 0 && !loading">
    <no-content />
  </template>
</template>

<script>
import { defineComponent, computed } from "vue";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import { formatDateElapsedTime, formatDate } from "@/utils/date";
import AssetDetailsLink from "@/components/links/AssetDetailsLink.vue";
import AccountLabel from "@/components/AccountLabel.vue";
import NoContent from "@/components/NoContent.vue";

export default defineComponent({
  name: "OffersSummary",
  components: { AssetDetailsLink, AccountLabel, NoContent },
  setup() {
    const store = useStore();
    const route = useRoute();

    const offers = computed(() => {
      switch (route.name) {
        case "AccountDetailsRoute":
          return store.getters["accounts/getAccount"].offers_r;
        default:
          return store.getters["offers/getLatestOffers"];
      }
    });

    const accountLabels = computed(() => store.getters["accounts/getLabels"]);
    const xlmLogo = require("@/assets/images/assets/xlm.png");
    const assetMeta = (asset) =>
      assetsMeta.value[asset.asset_code + "_" + asset.asset_issuer];

    const assetsMeta = computed(
      () => store.getters["assets/getAssetsMeta"].data
    );

    const loading = computed(() => store.getters["offers/getLoadingStatus"]);

    return {
      offers,
      formatDateElapsedTime,
      formatDate,
      accountLabels,
      assetMeta,
      xlmLogo,
      loading
    };
  },
});
</script>
